import { Accordion } from "@mantine/core";
import { FC } from "react";

import Link from "next/link";
import { AnimatedIcon } from "../../components/Button";
import { FAQ } from "../../content/faq";
import ChevronDownIcon from "../../public/webstatic/svg/arrow-down-icon.svg";

export const Faq: FC<{ faq?: typeof FAQ }> = ({ faq = FAQ }) => {
  return (
    <section className="bg-frecDarkBeige grid grid-cols-[auto_minmax(0,86rem)_auto] grid-rows-[auto_1fr_96px]">
      <div className="">{/* empty */}</div>
      <div className="dotted-v px-6 pt-9 pb-0 lg:mx-12 lg:border-x lg:px-16 lg:pt-16 lg:pb-9">
        <h2 className="h2">FAQ</h2>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className="">{/* empty */}</div>
      <div className="dotted-v px-6 lg:mx-12 lg:border-x lg:px-16">
        <Accordion
          chevron={<ChevronDownIcon />}
          classNames={{
            item: "border-frecBlack last:border-0",
            itemTitle: "",
            control: "px-0 cursor-pointer hover:bg-frecBlack/5",
            label: "py-6 lg:text-20",
            icon: "",
            chevron: "text-[16px] lg:text-[24px] w-10! justify-center",
            panel: "",
            content: "px-0 pb-8 text-[14px] lg:text-[16px]",
          }}
        >
          {faq.map(({ key, title, content }) => (
            <Accordion.Item value={key} key={key}>
              <Accordion.Control>{title}</Accordion.Control>
              <Accordion.Panel classNames={{ content: "space-y-4" }}>
                {content}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
        <hr className="border-frecBlack" />
        <Link
          href="https://help.frec.com/"
          className="group mt-8 flex items-center font-medium"
        >
          Visit our help center for more <AnimatedIcon />
        </Link>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className=""></div>
      <div className="dotted-v lg:mx-12 lg:border-x">{/* empty */}</div>
      <div className="">{/* empty */}</div>
    </section>
  );
};
