import Image from "next/legacy/image";
import Link from "next/link";

import { classNames } from "@frec-js/common-web";
import LinkedInLogo from "../public/webstatic/svg/LinkedIn.svg";
import TwitterLogo from "../public/webstatic/svg/Twitter.svg";
import { FrecWebPath } from "../utils/paths";

const SOCIAL_SIZE = 24;
const currentYear = new Date().getFullYear();

export const Footer = ({ className }: { className?: string }) => {
  return (
    <footer
      className={classNames(
        "text-frecDarkBeige bg-frecBlack grid grid-cols-[auto_minmax(0,86rem)_auto] text-center",
        className,
      )}
    >
      <div className="">{/* empty */}</div>
      <div className="dotted-v relative flex flex-col-reverse px-6 py-8 pb-9 lg:mx-12 lg:border-x lg:px-16">
        <div className="flex w-full flex-col items-center">
          <div className="mb-6 flex flex-row gap-4 font-normal">
            <Link
              href={FrecWebPath.about}
              className="hover:text-frecWhite hover:underline"
            >
              About
            </Link>
            <Link
              href="https://help.frec.com/"
              className="hover:text-frecWhite hover:underline"
            >
              Help center
            </Link>
            <Link
              href="mailto:help@frec.com"
              className="hover:text-frecWhite hover:underline"
            >
              Contact
            </Link>
            <Link
              href={FrecWebPath.disclosures}
              className="hover:text-frecWhite hover:underline"
            >
              Disclosures
            </Link>
            <Link
              href={FrecWebPath.rss}
              className="hover:text-frecWhite sr-only hover:underline"
              type="application/rss+xml"
              rel="alternate"
            >
              RSS
            </Link>
          </div>
          <div className="mb-6 flex flex-row items-center gap-4">
            <Link
              href="https://twitter.com/frecfinance"
              target="_blank"
              rel="noreferrer"
              title="Frec on Twitter"
            >
              <TwitterLogo
                alt="Twitter"
                className="fill-frecDarkBeige hover:fill-frecWhite"
                width={SOCIAL_SIZE}
                height={SOCIAL_SIZE}
              />
            </Link>
            <Link
              href="https://www.linkedin.com/company/frecfinance/"
              target="_blank"
              rel="noreferrer"
              title="Frec on LinkedIn"
            >
              <LinkedInLogo
                alt="LinkedIn"
                className="fill-frecDarkBeige hover:fill-frecWhite"
                width={SOCIAL_SIZE}
                height={SOCIAL_SIZE}
              />
            </Link>
            {/* <Link
                  href="https://www.instagram.com/frecfinance"
                  target="_blank"
                  rel="noreferrer"
                  title="Frec on Instagram"
                >
                  <InstaLogo
                    alt="Instagram"
                    className="fill-frecDarkBeige hover:fill-frecWhite"
                    width={SOCIAL_SIZE}
                    height={SOCIAL_SIZE}
                  />
                </Link> */}
          </div>
          <Link href={FrecWebPath.root} passHref={true} className="my-4">
            <Image
              alt="Frec logo"
              className="cursor-pointer p-3"
              src="/webstatic/logo/frec-white.svg"
              width={77}
              height={20}
            />
          </Link>
          <div className="text-12">
            © {currentYear} Frec Markets, Inc. All rights reserved.
          </div>
        </div>
      </div>
      <div className="">{/* empty */}</div>
    </footer>
  );
};
