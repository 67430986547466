import {
  DocumentLinks,
  EDITABLE_NUMBER_OF_SECTORS,
  EDITABLE_NUMBER_OF_STOCKS,
  formatUsd,
  GENERAL_MINIMUM_FOR_DIRECT_INDEXING,
  GENERAL_MINIMUM_FOR_LARGE_DIRECT_INDICES,
  HelpCenterArticleLinks,
} from "@frec-js/common";
import { TextLink } from "../components/Link";
import { FrecWebPath } from "../utils/paths";

export const FAQ = [
  {
    key: "differentFromIndexInvesting",
    title: "How is direct indexing different from index investing?",
    content: (
      <>
        <p>
          With an index ETF, you own a piece of the ETF fund. Meaning, you don’t
          own the stocks that are part of the index. However, with direct
          indexing, you own the underlying stock. You might be wondering: why go
          through all this trouble? The answer is tax savings. In an index ETF,
          the underlying stocks cannot be traded by you. But with direct
          indexing, you unlock the ability to sell stocks that are down to claim
          a loss, temporarily buy a different one while still tracking the
          index, and buy back the stocks you sold later.
        </p>
        <p>
          It’s kind of like earning points with your favorite airline, but
          instead of points, you earn “losses” that can be used as deductions
          against your tax bill. Plus, our software handles all of the
          individual stock trading for you without any work on your part, all
          while generating net new tax savings that you wouldn’t have gotten by
          just investing in the index. Keep in mind that you should consult your
          legal, tax, or financial advisor before making any financial
          decisions.
        </p>
      </>
    ),
  },
  {
    key: "howAssetsProtected",
    title: "How are my assets protected on Frec?",
    content: (
      <>
        <p>
          Frec’s brokerage firm, Frec Securities LLC, is a member of SIPC, which
          protects securities customers of its members up to $500,000 (including
          $250,000 for claims for cash). Explanatory brochure available upon
          request or at{" "}
          <TextLink href="https://www.sipc.org" target="_blank">
            www.sipc.org
          </TextLink>
          .
        </p>
        <p>
          Frec is not FDIC insured because we’re not a bank. FDIC insurance is
          only available for money held at FDIC-insured banks and protects
          deposit products like checkings and savings accounts.
        </p>
        <p>
          Your funds are held in your name at clearing house{" "}
          <TextLink
            href="https://apexfintechsolutions.com/solutions/clearing-custody/"
            target="_blank"
          >
            Apex Clearing
          </TextLink>
          , which works with other prominent financial technology companies
          .This means that you can easily access your funds and even create an
          account with Apex to monitor your assets outside of Frec.
        </p>
        <p>
          Our Registered Investment Adviser, Frec Advisers, is a fiduciary,
          meaning we are legally obligated to prioritize your interests over our
          own. Frec Advisers provides advisory services for our direct index
          strategies and Treasury services.
        </p>
      </>
    ),
  },
  {
    key: "transferStock",
    title: "Can I transfer stocks from my other brokerage?",
    content: (
      <>
        <p>
          Yes, you can easily transfer stocks from your other brokerage to Frec
          without selling them during the process (with the exception of
          fractional shares) along with your cost basis information.
        </p>
        <div>
          Stock transfers typically take between 4-6 business days. Your stocks
          will first arrive into your self-managed account. You can then trade
          them or move them into a direct index. Here is how stocks moved into a
          direct index are handled:
          <ul className="mt-4 space-y-4 pl-4">
            <li>
              a.{" "}
              <strong className="font-medium">
                Stock that is part of your direct index:
              </strong>{" "}
              You’ll see the amount of each stock that can be moved in-kind
              (without selling) into your chosen direct index, which is based on
              the target weight of each position within the index. The portion
              of stock exceeding the target weight will be sold over time in a
              tax-efficient manner. You can select which tax lots to move over.
            </li>
            <li>
              b.{" "}
              <strong className="font-medium">
                Stock that is not part of your direct index:
              </strong>{" "}
              Stocks and ETFs that sit outside of your direct index will be
              automatically sold over time, tax-efficiently.
            </li>
          </ul>
        </div>
        <p>
          There are two types of transfers: full transfers and partial
          transfers. With a full transfer, you are moving your entire portfolio
          to Frec (including ETFs, stocks, and other securities). With a partial
          transfer, you can select which assets to transfer.
        </p>
        <p>
          There are limitations on the type of securities that can be
          transferred into Frec. Learn more{" "}
          <TextLink
            href={
              HelpCenterArticleLinks[
                "What type of assets can you transfer into Frec?"
              ]
            }
            target="_blank"
          >
            here
          </TextLink>
          .
        </p>
      </>
    ),
  },
  {
    key: "roboAdvisors",
    title:
      "How is direct indexing different from ETF-level tax loss harvesting with robo-advisors?",
    content: (
      <>
        {" "}
        <p>
          Tax loss harvesting solutions offered by robo-advisors typically do
          ETF level tax loss harvesting, meaning the software will sell an ETF
          and purchase a similar but not “substantially identical” ETF to
          harvest losses instead of tax loss harvesting at the individual stock
          level. The downsides to this approach are there are less opportunities
          to tax loss harvest with ETFs, and the ETFs that are being sold and
          purchased may be very different from one another. With Frec Direct
          Indexing, we check for daily tax loss harvesting opportunities at the
          individual stock level and track a single index very closely.{" "}
          <strong className="font-medium">
            This can result in harvesting up to 2x more losses for direct
            indexing over ETF-to-ETF level tax loss harvesting.
          </strong>
          <sup>1</sup>
        </p>
        <p>
          Plus, robo-advisor products like Betterment and Wealthfront’s
          automated investing accounts charge a 0.25% advisory fee<sup>2</sup>{" "}
          in addition to ETF expense ratios (for example, the ETF SPY which
          tracks the S&P 500 has an expense ratio of 0.09%). At Frec, you can
          direct index for as little as an annual 0.10% advisory fee with no
          additional ETF expense ratio fees.
        </p>
        <p className="text-[12px] lg:text-[14px]">
          <sup>1</sup> Results are generated by Frec&apos;s direct indexing
          model tracking the S&P 500. They are hypothetical, do not reflect
          actual investment results, and not a guarantee of future results. The
          simulations used a $50,000 one-time deposit for a ten-year time frame
          of ninety-day increments from 12/17/03-6/10/22 (for ~40% data) and
          from 12/17/03-7/25/23 (for 2x ETF data). The ETF strategy comparison
          traded between SPY and IVV.
          <br />
          <br />
          <sup>2</sup> As of 03/28/2025. Pricing based on terms & conditions may
          vary.
        </p>
      </>
    ),
  },
  {
    key: "minimum",
    title: "What is the minimum to invest in Frec Direct Indexing?",
    content: (
      <p>
        The initial investment minimum to start a Frec Direct Indexing portfolio
        for most indices is {formatUsd(GENERAL_MINIMUM_FOR_DIRECT_INDEXING)}.
        For indices that have a greater number of positions, the minimum is{" "}
        {formatUsd(GENERAL_MINIMUM_FOR_LARGE_DIRECT_INDICES)}. This is the
        minimum starting amount in order to purchase almost every stock in the
        index, resulting in more accurate tracking of the index.
      </p>
    ),
  },
  {
    key: "newStrategy",
    title: "Is direct indexing a new strategy?",
    content: (
      <p>
        No, direct indexing has been around for decades and was primarily used
        by high net worth clients with financial advisors investing large sums
        of money. However, fractional and commission-free trading and modern
        software have made the strategy more accessible to more people. At Frec,
        our fees are as little as 0.10% for indices like the S&P 500 (compare
        this to SPY’s expense ratio of 0.09%) so that this strategy can be
        accessible for those who already adopt a passive investing strategy but
        want the benefit of tax loss harvesting. Note that direct indexing fees
        are AUM (assets under management) fees while ETF fees are fund fees
        expressed as an expense ratio.
        {/* Don't have this article yet
        <TextLink href="TODO" target="_blank">
          Read more about the differences between an ETF and Frec’s Direct
          Indexing fees here
        </TextLink> 
        */}
      </p>
    ),
  },
  {
    key: "tradeRestrictions",
    title:
      "What if the company I work for is part of an index and I’m subject to company trading windows?",
    content: (
      <>
        <p>
          You can restrict trading on up to 5 stocks, which will prevent buying
          and selling of these stocks across your direct index strategies. You
          can change these settings at any time.
        </p>
      </>
    ),
  },
  {
    key: "whenNotToInvest",
    title: "Who is direct indexing good for?",
    content: (
      <>
        <p>
          Direct indexing is ideal for individuals who are anticipating any type
          of capital gain in the future like selling stock or a house for
          profit. The same is true if you want to customize an index by removing
          or adding stocks / sectors. Direct indexing is less ideal for
          individuals who may not pay taxes or have capital gains now or in the
          future they will need to offset. It’s also not ideal for certain
          indices that have a high turnover of stocks, or an equal market-cap
          weighted index, or an actively managed ETF. The tax benefits of direct
          indexing are not relevant for non-taxable accounts.
        </p>
      </>
    ),
  },
  {
    key: "taxReturn",
    title: "Will my tax return require hundreds of trades to input?",
    content: (
      <p>
        No. Even though Frec makes hundreds of trades on your behalf to save you
        on taxes, the filing process is as simple as entering a number. At the
        mid or end of February every year, you’ll receive a 1099 consolidated
        tax form from Frec. There is a tax summary on page 2 of the form that
        shows your total net short-term and long-term gains or losses. You enter
        these consolidated numbers on your tax return. That&apos;s it.
      </p>
    ),
  },

  {
    key: "fullLiquidation",
    title: "What if I no longer want to invest in direct indexing?",
    content: (
      <>
        <p>
          You can stop direct indexing at any time by moving all of the stock
          from your direct indexing portfolio into your self-managed account.
          Once this is complete you will no longer be charged an annual AUM fee
          and you will be responsible for any trading.
        </p>
        <p>
          You can also request to move your stock off Frec’s platform to another
          brokerage firm. You can do this by initiating an ACATS transfer at the
          external brokerage firm (ACATS fees will apply). If you initiate a
          full transfer, all of your whole shares of stock will be transferred
          in kind and any fractional shares will be sold and transferred as
          cash.
        </p>
        <p>
          If you don’t want to hold as many individual stocks, you can always
          sell out of positions with smaller market cap and offset any potential
          capital gains from those sales with tax losses you’ve harvested. For
          example, by the end of March 2025, the top 20 stocks in the S&P 500
          made up 45% of the index. You could keep just a few of these top
          stocks, still capture most of the index&apos;s value, reduce the
          number of stocks you own, and lower your potential capital gains. This
          strategy can vary depending on the index you invest in.
        </p>
      </>
    ),
  },
  {
    key: "customize",
    title: "What can I customize?",
    content: (
      <p>
        You can add or exclude up to {EDITABLE_NUMBER_OF_STOCKS} stocks for all
        indices. You can also exclude up to {EDITABLE_NUMBER_OF_SECTORS} sectors
        for all indices except for the S&P 500
        <sup>Ⓡ</sup> Information Technology Index and the MVIS<sup>Ⓡ</sup> US
        Listed Semiconductor 25 Index.
      </p>
    ),
  },

  {
    key: "withdraw",
    title: "What if I want to withdraw funds from my direct index portfolio?",
    content: (
      <>
        <p>
          You can withdraw from your direct indexing portfolio at any time. We
          will deplete the cash balance in your direct index portfolio first,
          then attempt to sell out of your portfolio in a tax efficient manner
          that avoids wash sales and sells out of lots with a loss. However,
          depending on the size of your portfolio and the amount you want to
          withdraw, you could incur capital tax gains.
        </p>
        <p>
          You can also direct the dividends earned on stock within your direct
          index into cash or Treasury. You would then be able to withdraw from
          those accounts at any time without withdrawing from your direct
          indexing portfolio.
        </p>
        <p className="mt-4">
          Alternatively, Frec offers a portfolio line of credit which allows you
          to borrow up to 70% of your portfolio value{" "}
          <TextLink href={FrecWebPath.borrowRates}>(See rates)</TextLink>.
          Through Frec’s portfolio line of credit, you can get cash in your bank
          account as soon as the same day. Borrowing against a portfolio line of
          credit adds to your risks. Read more about those risks in{" "}
          <TextLink href={DocumentLinks["Margin Disclosure"]} target="_blank">
            Frec’s Margin Disclosure
          </TextLink>
          .
        </p>
        <p className="mt-4">
          You can also transfer your portfolio out of Frec by initiating an ACAT
          transfer at another brokerage firm (ACAT fees will apply). We will
          then transfer your stocks in-kind to that firm. Note that fractional
          shares cannot be transferred and will be sold and transferred as cash.
        </p>
      </>
    ),
  },
  {
    key: "trackIndex",
    title: "How does Frec track the index?",
    content: (
      <p>
        Our direct indexing algorithm attempts to track your index within a less
        than 1% tracking error of its benchmark ETF. Taking data licensed
        directly from the target index, our software aims to track an index’s
        performance closely while balancing this with tax loss harvesting
        opportunities. Tax loss harvesting causes a slight deviation from the
        benchmark ETF performance because tax loss harvesting requires selling
        of stock.{" "}
        <TextLink href={FrecWebPath.whitepaper} target="_blank">
          You can learn more about our methodology here
        </TextLink>
        .
      </p>
    ),
  },

  {
    key: "diversify",
    title: "Is direct indexing a good way to diversify concentrated stock?",
    content: (
      <p>
        Direct indexing can be a good way to reduce your tax burden if you have
        a concentrated stock position. First, you’re able to exclude that
        concentrated position from your index if it’s part of it, decreasing
        further concentration while still investing in the index. Additionally,
        you can slowly sell out of your concentrated position and invest into
        direct indexing. The tax losses from these sales can offset any capital
        gains, helping to reduce your tax burden. Start by selling stocks with a
        higher cost basis to lower your initial capital gains tax. Over time,
        the losses in your direct indexing portfolio can help offset larger
        future capital gains. This approach allows you to gradually reduce your
        concentrated stock position while managing your tax liability.
      </p>
    ),
  },
  {
    key: "ownStock",
    title: "Do I hold the individual stocks within my direct index?",
    content: (
      <p>
        Yes, you hold the underlying individual stocks that make up the index
        being tracked by your direct index portfolio. We manage the trading of
        those stocks for you by automatically buying and selling them in a way
        that aligns with our tax loss harvesting and index tracking strategy.
      </p>
    ),
  },
  {
    key: "brokerDealer",
    title:
      "Can I open a Frec account if I work at or if I’m affiliated with a broker dealer or registered investment adviser?",
    content: (
      <p>
        Yes, we permit those who currently work for or are affiliated with
        Broker Dealers or Registered Investment Advisers to open accounts at
        Frec. You should check with your employer about any potential trading
        restrictions you may be required to follow prior to opening an account
        with us. You will also be required to disclose your employer to us. If
        your employer only permits you to open managed accounts, we can restrict
        trading on your account for you and provide your compliance department
        with written documentation stating such.
      </p>
    ),
  },
  {
    key: "bankruptcy",
    title: "What happens if Frec goes out of business?",
    content: (
      <>
        <p>
          In the unlikely event that something happens to Frec, your assets will
          be fully accessible since they are held in your name at Apex Clearing.
          You can easily transfer your assets via ACAT to another brokerage at
          any time. Frec also has a six month process to notify customers in the
          very unlikely case we run out of funds and decide to shut down. Frec
          is very well-funded by top Silicon Valley venture capital firms.
        </p>
        <p>
          Frec’s goal is to offer its services for years to come and eventually
          scale to the largest direct indexing provider in the US. However, if
          Frec were to be acquired, your assets will continue to be managed by
          the new owner or you will always have the opportunity to move your
          assets to a different firm.{" "}
        </p>
      </>
    ),
  },
];
